<template>
  <asom-card v-if="!!data.length" class="">
    <div class="flow-root overflow-x-auto">
      <ul class="">
        <li v-for="(item, i) in defaultShownData" :key="item.transactionId">
          <div class="relative pb-8">
            <span
              v-if="i < defaultShownData.length - 1"
              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            ></span>
            <div>
              <div class="relative flex space-x-3">
                <div>
                  <span
                    class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                  >
                    <asom-icon
                      :icon="getIcon(item.type)"
                      size="sm"
                      class="text-white"
                    />
                  </span>
                </div>
                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    {{ item.message }}
                    <div v-for="(field, i) in item.fieldChanges" :key="i">
                      <div class="ml-5">
                        - 
                        <strong>
                          <slot :name="`field_${field.fieldName}`">
                            {{ startCase(field.fieldName) }}
                          </slot>
                        </strong> : {{ field.values.oldValue }}
                        <strong> --> </strong> {{ field.values.newValue }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-right text-sm whitespace-nowrap text-gray-500 flex items-center justify-between space-x-10"
                  >
                    <div class="">
                      by <strong>{{ item.officerName }}</strong>
                    </div>
                    <time :datetime="item.dateModified"
                      >on <strong>{{ displayUtcDateTime(item.dateModified) }}</strong></time
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <template v-if="showMoreActivities" #footer>
      <asom-button
        variant="link"
        icon="arrow-right"
        icon-position="right"
        @click="onShowMore"
        text="View all activities"
      />
    </template>
  </asom-card>
  <p v-else>No recent activity log</p>
</template>

<script>
import {startCase} from "lodash";
import { displayUtcDateTime } from "@/helpers/dateTimeHelpers";

export default {
  emits: ["show-more"],
  props: {
    data: {
      type: Array,
      required: true,
      validators: (array) =>
        array.every(
          (item) =>
            !!item &&
            !!item.transactionId &&
            !!item.message &&
            !!item.officerName &&
            !!item.dateModified
        ),
    },
    showMore: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      defaultShownData: [],
      showMoreActivities: this.showMore,
    }
  },
  mounted(){
    this.setDefaultData();
  },
  watch:{
    data(){
      this.setDefaultData();
    }
  },
  methods: {
    startCase,
    displayUtcDateTime,
    setDefaultData(){
      if(this.data.length > 10){
        this.showMoreActivities = true;
      }
      this.defaultShownData = this.data.slice(0, 10);
    },
    onShowMore() {
      this.$emit("show-more");
      this.defaultShownData = this.data;
      this.showMoreActivities = false;
    },
    getIcon(type) {
      let icon = "math-minus";
      switch (type) {
        case "event":
          icon = "file-document";
          break;
        case "form":
          icon = "format-text";
          break;
        case "cashbag":
          icon = "inbox";
          break;
        case "coinbox":
          icon = "toolbox";
          break;
        case "notebox":
          icon = "briefcase";
          break;
        case "coin":
        default:
          icon = "shape-circle";
          break;
      }
      return icon;
    },
  },
};
</script>
