<template>
  <div class="space-y-10">
    <div class="space-y-4">
      <asom-alert
        v-if="summary.openedCertisBagAmount > 0 || summary.isUsedCertisBagsPresent"
        variant="warning"
        title="Undeclared Manual Change"
        dismissible
      >
        <p class="text-sm">
          {{ $tc("screens.cashManagement.PSC.Main.hasUndeclaredTransaction") }}
          {{ $tc("screens.cashManagement.PSC.Main.transactionInCashFloat") }}
        </p>
      </asom-alert>
      <asom-alert
        v-if="summary.preEncodedTicketSoldAmount > 0"
        variant="warning"
        title="Undeclared Issued Tickets"
        dismissible
      >
        <p class="text-sm">
          {{ $tc("screens.cashManagement.PSC.Main.hasUndeclaredTransaction") }}
          {{ $tc("screens.cashManagement.PSC.Main.transactionInPET") }}
        </p>
      </asom-alert>
      <asom-alert
        v-if="summary.noOfTeBags > 0"
        variant="warning"
        title="Unmoved TE Bags"
        dismissible
      >
        <p class="text-sm">
          {{ $t("screens.cashManagement.PSC.Main.hasTeBagNotInAFC") }}
        </p>
      </asom-alert>
    </div>
    <article>
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="navigateToPSMManualRecord"
          icon="file-document"
          text="PSM Manual Record"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="navigateToCashFloatELog"
          icon="calendar-two"
          text="Manual Change"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="navigateToPreEncodedTicket"
          icon="tag"
          text="Pre-encoded Ticket"
        >
        </asom-button>
        <asom-button
          v-if="canCreatePSCDiscrepancyReport"
          variant="primary"
          outline
          rounded="none"
          @click="navigateToCreateDiscrepancyReport"
          icon="clipboard"
          text="Discrepancy Report"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <h3 class="subheader">
        {{ $t("screens.cashManagement.PSC.Main.stationOverviewTitle") }}
      </h3>
      <br />
      <dl
        class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-3"
      >
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              In Station
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.stationAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ formatCurrency(summary.stationLimitAllowance) }}</strong> Limit
              allowable
            </li>
          </ul>
        </aside>
      </dl>
    </article>
    <article>
      <h3 class="subheader">
        {{ $t("screens.cashManagement.PSC.Main.pscOverviewTitle") }}
      </h3>
      <br />
      <dl
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-3"
      >
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              PSM Manual Record Float Adj (+/-)
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{
                formatCurrency(
                  summary.psmAmount
                )
              }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ formatCurrency(summary.psmInflowAmount) }}</strong> PSM Float Adjustment (+)
            </li>
            <li class="px-3 py-2">
              <strong>{{ formatCurrency(summary.psmOutflowAmount) }}</strong> PSM Float Adjustment (-)
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              Manual Change
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.certisBagAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2 gap-1">
              <strong>{{ formatCurrency(summary.openedCertisBagAmount) }}</strong> <asom-badge variant="warning">Open</asom-badge>
            </li>
            <li class="px-3 py-2 gap-1">
              <strong>{{ formatCurrency(summary.closedCertisBagAmount) }}</strong> <asom-badge variant="success">Unopen</asom-badge>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              TE Bags in PSC
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.teBagAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ summary.noOfTeBags }}</strong> Number of bags
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              Pre-Encoded Ticket Events
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.preEncodedTicketSoldAmount) }}
              Sold
            </dd>
          </div>
          <ul class="font-semibold text-gray-900 text-left">
            <template
              v-for="(event, i) in summary.preEncodedTicketEventNames"
              :key="i"
            >
              <li class="px-3 py-2 border-b boder-gray-200">
                {{ event }}
              </li>
            </template>
          </ul>
        </aside>
      </dl>
    </article>
    <article>
      <h3 class="subheader">
        {{ $t("screens.cashManagement.Main.activityLogTitle") }}
      </h3>
      <br />
      <activity-log-list :data="summary.activityLogs">
        <template v-slot:field_noOfTickets>No. of Tickets</template>
      </activity-log-list>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { getPSCSummary } from "@/services/cashManagement.service";
import ActivityLogList from "../_ActivityLogList.vue";
import { formatCurrency } from "../../../helpers/numbers";

export default {
  components: {
    ActivityLogList,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      summary: {
        stationAmount: 0,
        stationLimitAllowance: 0,
        pscAmount: 0,
        pscLimitAllowance: 0,
        preEncodedTicketEventNames: [],
        preEncodedTicketSoldAmount: 0,
        preEncodedTicketRemainingAmount: 0,
        psmAmount: 0,
        psmInflowAmount: 0,
        psmOutflowAmount: 0,
        certisBagAmount: 0,
        openedCertisBagAmount: 0,
        closedCertisBagAmount: 0,
        teBagAmount: 0,
        noOfTeBags: 0,
        activityLogs: [],
        isUsedCertisBagsPresent: false,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.loadData();
    },
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      canCreatePSCDiscrepancyReport: 'auth/canCreatePSCDiscrepancyReport',
    }),
  },
  methods: {
    formatCurrency,
    async loadData() {
      this.error = null;
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const result = await getPSCSummary(this.pscId);
      if (result.success) {
        this.setData(result);
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    setData(apiResult) {
      this.summary.stationAmount = get(
        apiResult,
        "payload.data.stationAmount",
        0
      );
      this.summary.stationLimitAllowance = get(
        apiResult,
        "payload.data.stationLimitAllowance",
        0
      );
      this.summary.pscAmount = get(apiResult, "payload.data.pscAmount", 0);
      this.summary.pscLimitAllowance = get(
        apiResult,
        "payload.data.pscLimitAllowance",
        0
      );
      this.summary.preEncodedTicketEventNames = get(
        apiResult,
        "payload.data.preEncodedTicketEventNames",
        []
      );
      this.summary.preEncodedTicketSoldAmount = get(
        apiResult,
        "payload.data.preEncodedTicketSoldAmount",
        0
      );
      this.summary.preEncodedTicketRemainingAmount = get(
        apiResult,
        "payload.data.preEncodedTicketRemainingAmount",
        0
      );
      this.summary.psmAmount = get(apiResult, "payload.data.psmAmount", 0);
      this.summary.psmInflowAmount = get(
        apiResult,
        "payload.data.psmInflowAmount",
        0
      );
      this.summary.psmOutflowAmount = get(
        apiResult,
        "payload.data.psmOutflowAmount",
        0
      );
      this.summary.certisBagAmount = get(
        apiResult,
        "payload.data.certisBagAmount",
        0
      );
      this.summary.openedCertisBagAmount = get(
        apiResult,
        "payload.data.openedCertisBagAmount",
        0
      );
      this.summary.closedCertisBagAmount = get(
        apiResult,
        "payload.data.closedCertisBagAmount",
        0
      );
      this.summary.teBagAmount = get(apiResult, "payload.data.teBagAmount", 0);
      this.summary.noOfTeBags = get(apiResult, "payload.data.noOfTeBags", 0);
      this.summary.isUsedCertisBagsPresent = get(apiResult, "payload.data.isUsedCertisBagsPresent", false);
      let _activityLogs = get(
        apiResult,
        "payload.data.activityLogs",
        []
      );
      const fields = ['noOfTickets', 'psm', 'inputType', 'gtm', 'input', 'description', 'waived', 'payment', 'receipt'];
      _activityLogs.forEach( log => {
        let result = [];
        var obj = get(log, "fieldChanges", {});
        if(obj){
          fields.forEach( field => {
            if(obj[field] || obj[field + 'Updated']){
              let temp =
              { fieldName: field,
                values: {
                  oldValue: obj[field],
                  newValue: obj[field + 'Updated']
                }
              }
              result.push(temp);
            }
          })
        }
        log.fieldChanges = result;
      })
      this.summary.activityLogs = _activityLogs;
    },
    navigateToPreEncodedTicket() {
      this.$router.push({ name: "Pre Encoded Ticket Main Page" });
    },
    navigateToPSMManualRecord() {
      this.$router.push({ name: "PSM Manual Record Main Page" });
    },
    navigateToCashFloatELog() {
      this.$router.push({ name: "Cash Float e-Log" });
    },
    navigateToCreateDiscrepancyReport() {
      this.$router.push({ name: "Create Discrepancy Report PSC" });
    },
  },
};
</script>
